<template>
  <layout>
    <template v-slot:title> Tilbud </template>
    <template v-slot:content> </template>
  </layout>
</template>

<script>
import layout from "@/layouts/Default.vue";
export default {
  components: {
    layout,
  },
};
</script>

<style></style>
